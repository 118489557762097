<template>
  <div class="take-out-center">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'index',
  data () {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>
